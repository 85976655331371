<template>
  <div class="site-form-edit-wrapper">

    <b-notification auto-close :active.sync="notification.show" :type="notification.type">
      {{ notification.text }}
    </b-notification>

    <div class="site-form-edit-header">
      <b-button type="is-primary" @click="$router.go(-1)">
        {{ $t('back') }}
      </b-button>
    </div>

    <div class="site-form-edit-content-wrapper">
      <b-loading v-if="loading" :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>

      <div v-if="!loading && form" class="site-form-edit-content">
        <div class="form-item-wrapper">
          <div class="item-title">
            {{ $t('site_forms.form_name') }}:
          </div>

          <b-input v-model="form.name" :class="create_name_error ? 'name-input-error' : ''" :placeholder="'Введите название формы'"></b-input>
        </div>

        <div class="form-item-wrapper">
          <div class="item-title">
            Action:
          </div>

          <b-input v-model="form.action" :placeholder="'Введите action формы'"></b-input>
        </div>

        <div class="form-item-wrapper">
          <b-switch 
            :value="form.active"
            v-model="form.active"
            type="is-success"
          >
            Активность
          </b-switch>
        </div>

        <div class="form-item-wrapper">
          <b-switch 
            :value="form.subscription"
            v-model="form.subscription"
            type="is-success"
          >
            Подписка
          </b-switch>
        </div>

        <div class="form-item-wrapper">
          <b-switch 
            :value="form.banner_disabler"
            v-model="form.banner_disabler"
            type="is-success"
          >
            Banner disabler
          </b-switch>
        </div>

        <div class="form-item-wrapper">
          <b-switch 
            :value="form.basket"
            v-model="form.basket"
            type="is-success"
          >
            Basket
          </b-switch>
        </div>

        <div class="form-item-wrapper">
          <b-switch 
            :value="form.basket_finale"
            v-model="form.basket_finale"
            type="is-success"
          >
            Basket finale
          </b-switch>
        </div>

        <div class="form-item-wrapper">
          <div class="field-title">
            {{ $t('site_forms.fields') }}:
          </div>

          <div class="selected-fields-wrapper">

            <div class="fields-titles">
              <div>
                Название на сайте
              </div>

              <div>
                Alias в системе
              </div>

              <div>
                Тип поля
              </div>
            </div>

            <div class="selected-field-wrapper" v-for="field in selected_fields" :key="field.title_key">
              <div class="field-name-block">
                <b-input v-model="field.name" @input="editSelectedFieldName(field.title_key, field.name)"></b-input>
              </div>

              <div class="field-alias-block">
                <b-input v-model="field.alias" @input="editSelectedFieldAlias(field.title_key, field.alias)"></b-input>
              </div>

              <div class="field-title-block">
                <div class="field-selected-title">
                  {{ field.title }}
                </div>
              </div>

              <div class="delete-field">
                <img @click="deleteField(field)" style="width: 20px; height: 20px;" src="data:image/svg+xml;base64,
                  PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMiI+PGc+PHBhdGggZD0ibTI1NiAwYy0xNDEuMTY0MDYyIDAtMjU2IDExNC44MzU5MzgtMjU2IDI1NnMxMTQuODM1OTM4IDI1NiAyNTYgMjU2IDI1Ni0xMTQuODM1OTM4IDI1Ni0yNTYtMTE0LjgzNTkzOC0yNTYtMjU2LTI1NnptMCAwIiBmaWxsPSIjZjQ0MzM2IiBkYXRhLW9yaWdpbmFsPSIjRjQ0MzM2Ij48L3BhdGg+PHBhdGggZD0ibTM1MC4yNzM0MzggMzIwLjEwNTQ2OWM4LjMzOTg0MyA4LjM0Mzc1IDguMzM5ODQzIDIxLjgyNDIxOSAwIDMwLjE2Nzk2OS00LjE2MDE1NyA0LjE2MDE1Ni05LjYyMTA5NCA2LjI1LTE1LjA4NTkzOCA2LjI1LTUuNDYwOTM4IDAtMTAuOTIxODc1LTIuMDg5ODQ0LTE1LjA4MjAzMS02LjI1bC02NC4xMDU0NjktNjQuMTA5Mzc2LTY0LjEwNTQ2OSA2NC4xMDkzNzZjLTQuMTYwMTU2IDQuMTYwMTU2LTkuNjIxMDkzIDYuMjUtMTUuMDgyMDMxIDYuMjUtNS40NjQ4NDQgMC0xMC45MjU3ODEtMi4wODk4NDQtMTUuMDg1OTM4LTYuMjUtOC4zMzk4NDMtOC4zNDM3NS04LjMzOTg0My0yMS44MjQyMTkgMC0zMC4xNjc5NjlsNjQuMTA5Mzc2LTY0LjEwNTQ2OS02NC4xMDkzNzYtNjQuMTA1NDY5Yy04LjMzOTg0My04LjM0Mzc1LTguMzM5ODQzLTIxLjgyNDIxOSAwLTMwLjE2Nzk2OSA4LjM0Mzc1LTguMzM5ODQzIDIxLjgyNDIxOS04LjMzOTg0MyAzMC4xNjc5NjkgMGw2NC4xMDU0NjkgNjQuMTA5Mzc2IDY0LjEwNTQ2OS02NC4xMDkzNzZjOC4zNDM3NS04LjMzOTg0MyAyMS44MjQyMTktOC4zMzk4NDMgMzAuMTY3OTY5IDAgOC4zMzk4NDMgOC4zNDM3NSA4LjMzOTg0MyAyMS44MjQyMTkgMCAzMC4xNjc5NjlsLTY0LjEwOTM3NiA2NC4xMDU0Njl6bTAgMCIgZmlsbD0iI2ZhZmFmYSIgZGF0YS1vcmlnaW5hbD0iI0ZBRkFGQSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkFGQUZBIj48L3BhdGg+PC9nPiA8L3N2Zz4=" />
              </div>
            </div>

          </div>

          <div class="new-filed-wrapper" v-if="unselected_fields && unselected_fields.length > 0">

            <div class="field-name-block">
              <b-input v-model="new_field.name"></b-input>
            </div>

            <div class="field-alias-block">
              <b-input v-model="new_field.alias"></b-input>
            </div>

            <b-dropdown aria-role="list">
              <template slot="trigger">
                <div :class="new_field_error ? 'select-input error' : 'select-input'">
                  {{ new_field.title || 'Выберите тип поля' }}
                </div>
              </template>
              <b-dropdown-item aria-role="listitem" @click="selectField(field)" v-for="(field, index) in unselected_fields" :key="index" :class="field.title_key === new_field.title_key ? 'active' : ''">
                {{ field.title }}
              </b-dropdown-item>
            </b-dropdown>

            <div class="add-button">
              <img @click="addNewField()" style="width: 20px; height: 20px;" src="data:image/svg+xml;base64,
                PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMiIgY2xhc3M9ImhvdmVyZWQtcGF0aHMiPjxnPjxwYXRoIGQ9Im0yNTYgMGMtMTQxLjE2NDA2MiAwLTI1NiAxMTQuODM1OTM4LTI1NiAyNTZzMTE0LjgzNTkzOCAyNTYgMjU2IDI1NiAyNTYtMTE0LjgzNTkzOCAyNTYtMjU2LTExNC44MzU5MzgtMjU2LTI1Ni0yNTZ6bTAgMCIgZmlsbD0iIzIxOTZmMyIgZGF0YS1vcmlnaW5hbD0iIzIxOTZGMyIgY2xhc3M9ImhvdmVyZWQtcGF0aCIgc3R5bGU9ImZpbGw6Izc5NTdENSIgZGF0YS1vbGRfY29sb3I9IiMyMTk2ZjMiPjwvcGF0aD48cGF0aCBkPSJtMzY4IDI3Ny4zMzIwMzFoLTkwLjY2Nzk2OXY5MC42Njc5NjljMCAxMS43NzczNDQtOS41NTQ2ODcgMjEuMzMyMDMxLTIxLjMzMjAzMSAyMS4zMzIwMzFzLTIxLjMzMjAzMS05LjU1NDY4Ny0yMS4zMzIwMzEtMjEuMzMyMDMxdi05MC42Njc5NjloLTkwLjY2Nzk2OWMtMTEuNzc3MzQ0IDAtMjEuMzMyMDMxLTkuNTU0Njg3LTIxLjMzMjAzMS0yMS4zMzIwMzFzOS41NTQ2ODctMjEuMzMyMDMxIDIxLjMzMjAzMS0yMS4zMzIwMzFoOTAuNjY3OTY5di05MC42Njc5NjljMC0xMS43NzczNDQgOS41NTQ2ODctMjEuMzMyMDMxIDIxLjMzMjAzMS0yMS4zMzIwMzFzMjEuMzMyMDMxIDkuNTU0Njg3IDIxLjMzMjAzMSAyMS4zMzIwMzF2OTAuNjY3OTY5aDkwLjY2Nzk2OWMxMS43NzczNDQgMCAyMS4zMzIwMzEgOS41NTQ2ODcgMjEuMzMyMDMxIDIxLjMzMjAzMXMtOS41NTQ2ODcgMjEuMzMyMDMxLTIxLjMzMjAzMSAyMS4zMzIwMzF6bTAgMCIgZmlsbD0iI2ZhZmFmYSIgZGF0YS1vcmlnaW5hbD0iI0ZBRkFGQSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkFGQUZBIj48L3BhdGg+PC9nPiA8L3N2Zz4=" />
            </div>
          </div>
        </div>

        <b-button v-if="!this.$router.currentRoute.params.form_id || this.$router.currentRoute.params.form_id === 'new'" class="save-button" type="is-primary" @click="createSiteForm">
          {{ $t('create') }}
        </b-button>

        <b-button v-if="this.$router.currentRoute.params.form_id && this.$router.currentRoute.params.form_id !== 'new'" class="save-button" type="is-primary" @click="checkNeedUpdateRequest">
          {{ $t('save') }}
        </b-button>

        <b-button v-if="this.$router.currentRoute.params.form_id && this.$router.currentRoute.params.form_id !== 'new'" class="delete-button" type="is-primary" @click="confirmDeleteSiteForm">
          {{ $t('delete') }}
        </b-button>
      </div>
    </div>

    <b-modal :active.sync="delete_modal.show" class="site-form-modal" :can-cancel="false">
      <div class="custom-close-modal" @click="delete_modal.show = !delete_modal.show">
        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z" fill="#2C3236"/>
        </svg>
      </div>
      <div class="site-form-modal-loading" v-show="delete_modal.loading">
        <b-loading :is-full-page="false" :active.sync="delete_modal.loading" :can-cancel="false"></b-loading>
      </div>
      <div v-show="!delete_modal.loading">
        <div class="site-form-modal-title">
          {{ $t('site_forms.question_delete_site_form') }}
        </div>
        <div class="buttons-wrapper">
          <b-button type="is-primary" @click="delete_modal.show = false">
            {{ $t('cancel') }}
          </b-button>
          <b-button type="is-primary" @click="deleteSiteForm">
            {{ $t('delete') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="update_modal.show" class="site-form-modal" :can-cancel="false">
      <div class="custom-close-modal" @click="update_modal.show = !update_modal.show">
        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z" fill="#2C3236"/>
        </svg>
      </div>
      <div class="site-form-modal-loading" v-show="update_modal.loading">
        <b-loading :is-full-page="false" :active.sync="update_modal.loading" :can-cancel="false"></b-loading>
      </div>
      <div v-show="!update_modal.loading">
        <div class="site-form-modal-title">
          {{ $t('site_forms.question_update_site_form') }}
        </div>
        <div class="buttons-wrapper">
          <b-button type="is-primary" @click="update_modal.show = false">
            {{ $t('cancel') }}
          </b-button>
          <b-button type="is-primary" @click="updateSiteForm">
            {{ $t('update') }}
          </b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { deepCompareObjects } from '@/utils/index.js'

export default {
  name: 'SiteFormEdit',
  data () {
    return {
      loading: true,
      form: null,
      fields: {
        'name': 'Имя',
        'last_name': 'Фамилия',
        'email': 'Почта',
        'sex': 'Пол',
        'birthday': 'Дата рождения',
        'phone': 'Телефон',
        'index': 'Индекс',
        'region': 'Регион',
        'country': 'Страна',
        'city': 'Город',
        'address': 'Адрес',
        'fax': 'Факс',
        'position': 'Должность',
        'company': 'Название компании',
        'order_id': 'Номер заказа',
        'misc': 'Дополнительная информация',
        'cart_sum': 'Сумма заказа',
        'Not used': 'Не используется',
        'sub_type': 'Тип подписки',
        'discount_size': 'Размер скидки',
        'add_1': 'Дополнительное поле - 1',
        'add_2': 'Дополнительное поле - 2',
        'add_3': 'Дополнительное поле - 3',
        'add_4': 'Дополнительное поле - 4',
        'add_5': 'Дополнительное поле - 5',
        'hidden': 'Скрыто от пользователя',
      },
      new_field: {
        name: '',
        alias: '',
        title: '',
        title_key: ''
      },
      new_field_error: false,
      selected_fields: null,
      unselected_fields: null,
      delete_modal: {
        show: false,
        loading: false
      },
      update_modal: {
        show: false,
        loading: false
      },
      notification: {
        show: false,
        type: '',
        text: ''
      },
      create_name_error: false,
    }
  },
  created () {
    if(this.$router.currentRoute.params.form_id && this.$router.currentRoute.params.form_id !== 'new') {
      this.$store.dispatch('getSiteFormInfo', {
        vue: this,
        callback: this.setSiteFormInfo
      })
    }
    else {
      this.setSiteFormInfo({
        name: '',
        basket: false,
        active: false,
        action: '',
        banner_disabler: false,
        basket_finale: false,
        subscription: false,
        fields: {},
        fields_aliases: {}
      })
    }
  },
  watch: {
    'form.name' () {
      this.create_name_error = false
    }
  },
  methods: {
    setSiteFormInfo (form) {
      this.$nextTick(function() {
        this.form = form
        this.loading = false
        this.setSelectedFields()
        this.setUnselectedFields()
      }.bind(this))
    },
    editSelectedFieldName (title_key, name) {
      this.form.fields[title_key] = name
    },
    editSelectedFieldAlias (title_key, alias) {
      this.form.fields_aliases[title_key] = alias
    },
    setUnselectedFields () {
      this.unselected_fields = Object.keys(this.fields).map(function(key) {
        return {
          name: '',
          alias: '',
          title: this.fields[key],
          title_key: key
        }
      }.bind(this))
      if(this.form && this.form.fields && Object.keys(this.form.fields).length > 0) {
        this.unselected_fields = this.unselected_fields.filter(function(field) {
          return !this.form.fields.hasOwnProperty(field.title_key)
        }.bind(this))
      }
    },
    setSelectedFields () {
      if(this.form && this.form.fields && Object.keys(this.form.fields).length > 0) {
        this.selected_fields = Object.keys(this.form.fields).map(function(key) {
          return {
            name: this.form.fields[key],
            alias: this.form.fields_aliases[key],
            title: this.fields[key],
            title_key: key
          }
        }.bind(this))

        this.new_field.name = ''
        this.new_field.alias = ''
        this.new_field.title = ''
        this.new_field.title_key = ''
      }
      else if (this.form && this.form.fields && Object.keys(this.form.fields).length === 0) {
        this.selected_fields = null
      }
    },
    deleteField (field) {
      delete this.form.fields[field.title_key]
      delete this.form.fields_aliases[field.title_key]
      this.setSelectedFields()
      this.setUnselectedFields()
    },
    selectField (field) {
      this.new_field_error = false
      this.new_field.title = field.title
      this.new_field.title_key = field.title_key
    },
    addNewField () {
      if(this.new_field.title_key) {
        this.form.fields[this.new_field.title_key] = this.new_field.name
        this.form.fields_aliases[this.new_field.title_key] = this.new_field.alias
        this.setSelectedFields()
        this.setUnselectedFields()
      }
      else {
        this.new_field_error = true
      }
    },
    checkNeedUpdateFields () {
      let compare_fields = deepCompareObjects(this.$store.getters.form.fields, this.form.fields)
      let compare_fields_aliases = deepCompareObjects(this.$store.getters.form.fields_aliases, this.form.fields_aliases)

      if(!compare_fields || !compare_fields_aliases) {
        return true
      }
      else if(compare_fields && compare_fields_aliases) {
        return false
      }
    },
    checkNeedUpdateRequest () {
      if(this.form.banner_disabler !== this.$store.getters.form.banner_disabler ||
         this.form.active !== this.$store.getters.form.active ||
         this.form.basket_finale !== this.$store.getters.form.basket_finale ||
         this.form.basket !== this.$store.getters.form.basket ||
         this.form.action !== this.$store.getters.form.action ||
         this.form.subscription !== this.$store.getters.form.subscription ||
         this.form.name !== this.$store.getters.form.name ||
         this.checkNeedUpdateFields()) {

        this.update_modal.show = true
      }
      else {
        this.notification.text = this.$t('site_settings.no_changes')
        this.notification.type = 'is-info'
        this.notification.show = true
      }
    },
    confirmDeleteSiteForm () {
      this.delete_modal.show = true
    },
    createSiteForm () {
      if(this.form.name.length >= 5) {
        this.loading = true
        this.$store.dispatch('createSiteForm', {
          vue: this,
          callback: function (response) {
            if(response !== 'error') {
              this.$router.push({ name: 'SiteFormEdit', params: { site_id: this.$router.currentRoute.params.site_id, form_id: response.body.form.id } })
              window.location.reload()
            }
            else {
              this.loading = false
              this.notification.text = this.$t('site_forms.create_error_message')
              this.notification.type = 'is-danger'
              this.notification.show = true
            }
          }.bind(this)
        })
      }
      else {
        this.create_name_error = true
        this.notification.text = this.$t('site_forms.form_name_length_error')
        this.notification.type = 'is-danger'
        this.notification.show = true
      }
    },
    deleteSiteForm () {
      this.delete_modal.loading = true
      this.$store.dispatch('deleteSiteForm', {
        vue: this,
        callback: function (response) {
          if(response !== 'error') {
            this.$router.push({name: 'SiteForms', params: {site_id: this.$router.currentRoute.params.site_id}})
          }
          else {
            this.delete_modal.loading = false
            this.delete_modal.show = false

            this.notification.text = this.$t('site_forms.delete_error_message')
            this.notification.type = 'is-danger'
            this.notification.show = true
          }
        }.bind(this)
      })
    },
    updateSiteForm () {
      this.update_modal.loading = true
      this.$store.dispatch('updateSiteForm', {
        vue: this,
        callback: function (response) {
          this.update_modal.loading = false
          this.update_modal.show = false
          if(response !== 'error') {
            this.notification.text = this.$t('site_forms.update_success_message')
            this.notification.type = 'is-success'
          }
          else {
            this.notification.text = this.$t('site_forms.update_error_message')
            this.notification.type = 'is-danger'
          }
          this.notification.show = true
        }.bind(this)
      })
    },
  }
}
</script>

<style lang="scss">
.site-form-edit-wrapper {
  position: relative;

  .site-form-edit-content-wrapper {
    position: relative;

    .loading-overlay {
      width: 100%;
      min-height: 300px;
    }
    .site-form-edit-content {
      margin-top: 20px;
      border-radius: 4px;
      padding: 15px;
      border: 1px solid #dbdbdb;

      .form-item-wrapper {
        margin-bottom: 15px;

        .fields-titles {
          display: flex;

          div {
            width: 250px;
            margin-right: 15px;
          }
        }
        input {
          width: 250px;
        }
        .name-input-error {

          input {
            border: 1px solid red;
          }
          input::placeholder {
            color: rgba(255, 0, 0, 0.6);
          }
        }
      }
      .field-title {
        margin-bottom: 5px;
      }
      .selected-fields-wrapper {
        

        .selected-field-wrapper {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;

          .field-name-block, .field-alias-block, .field-title-block {
            margin-right: 15px;
          }
          .field-title-block:first-child {
            height: 60px;
          }
          .field-title-block {
            width: 250px;

            .field-selected-title {
              height: 36px;
              display: flex;
              align-items: center;
              padding: 0 10px;
            }
          }
          .delete-field {
            padding-bottom: 3px;

            img {
              cursor: pointer;
              border-radius: 20px;
            }
            img:hover {
              box-shadow: 0px 0px 5px grey;
            }
          }
        }
      }
      .new-filed-wrapper {
        display: flex;
        margin-bottom: 30px;

        .add-button {
          cursor: pointer;
          display: flex;
          align-items: center;

          img {
            border-radius: 20px;
          }
          img:hover {
            box-shadow: 0px 0px 5px grey;
          }
        }
        .field-name-block, .field-alias-block {
          margin-right: 15px;
        }
        .dropdown { 
          margin-right: 15px;
        }
        .dropdown .dropdown-trigger {
          width: 250px;

          .select-input.error {
            color: red;
            border: 1px solid red
          }
        }
        .dropdown-menu .dropdown-content {
          max-height: 200px;
          overflow-x: auto;

          a.dropdown-item.active {
              background-color: whitesmoke;
              color: #0a0a0a;
          }
        }
      }
      .save-button {
        margin-right: 15px;
      }
    }
  }

  .modal {

    .modal-content {
      background: white;
      padding: 20px;
      width: 450px;
      height: 190px;

      .custom-close-modal {
        display: flex;
        justify-content: flex-end;

        svg {
          cursor: pointer;
        }
      }
      .site-form-modal-title {
        font-size: 18px;
        margin-bottom: 35px;
        margin-top: 30px;
      }
      .buttons-wrapper {
        display: flex;
        justify-content: center;

        button:first-child {
          margin-right: 15px;
        }
      }
    }
  }
  .site-form-modal {
    
    .site-form-modal-loading {
      width: 100%;
      height: 120px;

      .loading-overlay {
        position: relative;
        height: inherit;
      }
    }
  }
}
</style>